import { defineComponent } from 'vue';
import Breadcrumb from '@/components/Breadcrumb.vue';
import PrivacyContent from '@/components/privacy/content.vue';
export default defineComponent({
  name: 'PrivacyMobile',
  components: {
    Breadcrumb: Breadcrumb,
    PrivacyContent: PrivacyContent
  },
  inject: ['isMobile']
});